@import 'style/mixins';

.BlockQuote {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__quote {
    @include text-styles-lead;
  }

  &__author {
    @include text-styles-meta;
  }
}
