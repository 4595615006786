@import 'style/mixins';

.BlockExpanderItem {
  margin-bottom: 12px;

  &__teaser {
    position: relative;
    padding: 0 var(--padding-horizontal);
    user-select: none;
    cursor: pointer;

    &__grid {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-areas: 'subtitle title';
      gap: 0 var(--grid-gutter);

      &.h-no-subtitle {
        grid-template-columns: 1fr;
        grid-template-areas: 'title';
      }

      @include viewport-zero-to-s {
        grid-template-columns: 1fr;
        grid-template-areas:
          'subtitle'
          'title';
        border-top: $border-normal;
      }
    }

    &__subtitle {
      @include typo-meta-sans;
      grid-area: subtitle;
      display: grid;
      align-items: center;
      font-weight: 700;
      border-top: $border-normal;

      @include viewport-zero-to-s {
        border: 0;
      }
    }

    &__title {
      @include typo-lead-sans;
      grid-area: title;
      display: grid;
      grid-template-columns: auto min-content 1fr;
      grid-template-areas: 'title thumb icon';
      gap: 0 var(--grid-gutter);
      font-weight: 700;
      color: var(--color-secondary);
      border-top: $border-normal;

      @include viewport-zero-to-s {
        line-height: var(--fs-lead);
        border: 0;
      }

      &__text {
        grid-area: title;
      }

      &__thumb {
        position: relative;
        top: -1px;
        grid-area: thumb;
        width: auto;
        height: $project-thumb-height;
      }

      &__icon {
        @include typo-lead-sans;
        position: relative;
        top: -3px;
        grid-area: icon;
        justify-self: right;
        color: var(--color-secondary);

        @include viewport-zero-to-s {
          position: absolute;
          top: -7px;
          right: var(--padding-horizontal);
        }
      }
    }
  }

  &__detail {
    overflow: hidden;

    &__content {
      padding-top: 5px;

      &__abstract {
        @include text-styles-lead;
        padding: 0 var(--padding-horizontal);
      }
    }
  }
}
