@import 'style/mixins';

.BlockVideo {
  position: relative;

  &__caption {
    @include text-styles-meta;
    padding: 0 var(--padding-horizontal);
  }

  .Video {
    max-height: 90vh;
  }
}
