@import 'style/mixins';

body.h-not-found {
  overflow: hidden;
}

.NotFound {
  &__text {
    @include typo-lead-sans;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-index-intro-text;
    transform: translate3d(-50%, -50%, 0);
    font-weight: 700;
    text-align: center;
    color: $color-white;
    mix-blend-mode: difference;
  }
}
