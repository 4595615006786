@import 'style/mixins';

.BlockIndex {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'meta content';
    gap: 0 var(--grid-gutter);
    margin-bottom: 20px;

    @include viewport-zero-to-s {
      grid-template-areas:
        'meta .'
        'content content';
    }

    &__meta {
      position: relative;
      grid-area: meta;
      border-top: $border-normal;

      &__link {
        display: grid;
        grid-template-columns: auto max-content;
      }

      &__title {
        @include typo-lead-sans;
        padding-right: 10px;
        font-weight: 700;
        color: var(--color-secondary);
      }

      &__thumb {
        position: relative;
        top: -1px;
        width: auto;
        height: $project-thumb-height;
      }
    }

    &__content {
      position: relative;
      grid-area: content;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      height: 100%;
      padding-top: 5px;
      border-top: $border-normal;

      @include viewport-zero-to-s {
        padding-top: 0;
        border-top: 0;
      }

      &__description {
        @include text-styles-meta;
      }
    }
  }
}
