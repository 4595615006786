@import 'style/mixins';

.BlockExpander {
  position: relative;
  width: 100%;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }
}
