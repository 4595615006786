@import 'style/mixins';

.VideoPlayer {
  &__inner {
    position: relative;
    width: 100%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-white;
    }
  }

  &__btn__play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: block;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    appearance: none;

    svg {
      position: absolute;
      top: 51%;
      left: 50%;
      width: 100px;
      height: 100px;
      fill: $color-white;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &__btn__play__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: block;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    appearance: none;
  }

  &__controls {
    @include typo-meta-sans;
    position: static;
    margin-top: -44px;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    padding: 0 var(--padding-horizontal);
    font-weight: 700;

    &--active {
      opacity: 1;
    }

    &__pause__button,
    &__mute__button {
      position: relative;
      padding: 0 10px;
      color: $color-white;
      font-weight: 700;
      vertical-align: top;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      mix-blend-mode: difference;
    }

    input[type='range'] {
      appearance: none;
      cursor: pointer;
      background: transparent;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        cursor: pointer;
        border: 0;
        appearance: none;
        transition: all 0.4s ease;
      }

      &::-moz-range-track {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: transparent;
        border: 0;
      }

      &:focus::-moz-range-track {
        outline: none;
      }

      &::-webkit-slider-thumb {
        width: 10px;
        height: 100%;
        cursor: pointer;
        background: transparent;
        border: 0;
        appearance: none;
      }

      &::-moz-range-thumb {
        width: 10px;
        height: 100%;
        cursor: pointer;
        background: transparent;
        border: 0;
        appearance: none;
      }

      &:focus::-webkit-slider-runnable-track {
        background: transparent;
      }
    }

    &__left {
      button {
        padding-left: 0;
      }
    }

    &__center {
      flex: 10 1 auto;
    }

    &__right {
      text-align: right;

      button {
        padding-right: 0;
      }
    }

    &__progress {
      position: relative;
      height: 8px;

      &__bar {
        position: absolute;
        top: 2px;
        width: 100%;
        height: 1px;
        pointer-events: none;

        &__bg {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: $color-white;
          mix-blend-mode: difference;
        }

        span {
          position: relative;
          top: -3px;
          z-index: 3;
          height: 8px;
          display: block;
          background: $color-blue;
        }
      }

      &__seek {
        position: absolute;
        top: -100%;
        width: 100%;
        height: 300%;
        margin: 0;
        cursor: pointer;

        .no-touchevents &:hover + &__tooltip {
          display: block;
        }

        &__tooltip {
          position: absolute;
          top: -26px;
          display: none;
          padding: 2px 6px 1px;
          color: $color-white;
          word-break: keep-all;
          white-space: pre;
          mix-blend-mode: difference;
          transform: translateX(-50%);

          &--active {
            display: block;
          }
        }
      }
    }
  }
}
