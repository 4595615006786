@import 'style/mixins';

.BlockImage {
  position: relative;

  &--left &__figures {
    grid-template-columns: repeat(2, 1fr);

    @include viewport-s {
      grid-template-columns: 67fr 33fr;
    }

    @include viewport-xs {
      grid-template-columns: 1fr;
    }

    @include viewport-zero {
      grid-template-columns: 1fr;
    }
  }

  &--text {
    .BlockImage__figures {
      grid-template-columns: repeat(#{$grid-columns}, 1fr);
      grid-auto-columns: auto;
      padding: 0 var(--padding-horizontal);
    }

    .BlockImage__figure {
      grid-column: 3 / 11;

      @include viewport-m {
        grid-column: 2 / 12;
      }

      @include viewport-s {
        grid-column: 2 / 12;
      }

      @include viewport-xs {
        grid-column: 1 / 13;
      }

      @include viewport-zero {
        grid-column: 1 / 13;
      }
    }
  }

  &__figures {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 0 var(--grid-gutter);

    @include viewport-xs {
      display: block;
    }

    @include viewport-zero {
      display: block;
    }
  }

  &__figure {
    position: relative;
    width: 100%;
    max-height: 90vh;
    margin: 0;
  }

  &__ratio {
    height: 0;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__caption {
    @include text-styles-meta;
    padding: 0 var(--padding-horizontal);
  }
}
