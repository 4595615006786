@import 'style/mixins';

.ProjectsSlideshow {
  padding: $content-block-padding-top 0 0;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__slideshow {
    position: relative;

    .swiper-slide:first-of-type {
      margin-left: calc(
        var(--padding-horizontal) - var(--slideshow-button-width) - 10px
      );

      .ProjectsSlideshow__item__figure__img {
        max-width: calc(100vw - var(--slideshow-button-width) - 20px);
      }
    }

    .swiper-slide:last-of-type {
      margin-right: var(--padding-horizontal);

      .ProjectsSlideshow__item__figure__img {
        max-width: calc(100vw - var(--slideshow-button-width) - 20px);
      }
    }

    .h-slideshow-button-bg {
      position: absolute;
      top: 0;
      z-index: 10;
      width: var(--slideshow-button-width);
      height: 100%;
      background: var(--color-secondary);
      mix-blend-mode: multiply;
      user-select: none;
      cursor: pointer;
    }

    .h-slideshow-button-icon {
      @include typo-lead-sans;
      position: absolute;
      top: 0;
      z-index: 15;
      width: var(--slideshow-button-width);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      user-select: none;
      cursor: pointer;
    }

    &__button__left,
    &__button__right {
      &.swiper-button-disabled {
        display: none;
      }
    }

    &__button__left {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        left: 0;
      }
    }

    &__button__right {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        right: 0;
      }
    }
  }

  &__item {
    display: block;

    &__title h2 {
      @include typo-meta-sans;
      font-weight: 700;
      color: var(--color-secondary);
    }

    &__link {
      display: block;
    }

    &__meta {
      @include text-styles-meta;
    }

    &__figure {
      position: relative;
      height: $projects-slideshow-img-height;
      margin: 0;

      &__img {
        width: auto;
        max-width: calc(100vw - 2 * var(--slideshow-button-width) - 20px);
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
