@import 'style/mixins';

.BlockText {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &.h-content-indent &__content {
    text-indent: $indent-text;
  }

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(#{$grid-columns}, 1fr);
    gap: 0 var(--grid-gutter);
  }

  &__content {
    grid-column: 3 / 11;
    @include text-styles-text;

    @include viewport-m {
      grid-column: 2 / 12;
    }

    @include viewport-s {
      grid-column: 2 / 12;
    }

    @include viewport-xs {
      grid-column: 1 / 13;
    }

    @include viewport-zero {
      grid-column: 1 / 13;
    }
  }
}
