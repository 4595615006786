@import 'style/mixins';

.Home {
  position: relative;
  padding: var(--padding-vertical) 0 var(--padding-vertical-bottom);

  .h-has-cookie-banner & {
    padding-bottom: var(--padding-vertical-bottom-cookie);
  }

  &__title {
    @include typo-lead-sans;
    position: relative;
    left: 0;
    width: 100%;
    padding-bottom: calc(0.5 * var(--lh-lead));
    margin-top: -12px;
    font-weight: 700;
    text-align: center;

    @include viewport-xs {
      padding-bottom: 0;
    }

    @include viewport-zero {
      padding-bottom: 0;
    }

    .h-intro & {
      opacity: 0;
    }

    &__intro {
      padding-bottom: calc(0.5 * var(--lh-lead));

      @include viewport-xs {
        padding-bottom: 0;
      }

      @include viewport-zero {
        padding-bottom: 0;
      }

      span {
        opacity: 0;
      }
    }

    &__logo {
      @include viewport-xs {
        display: grid;
        align-items: center;
        justify-content: center;
        height: calc(100vw);
      }

      @include viewport-zero {
        display: grid;
        align-items: center;
        justify-content: center;
        height: calc(100vw);
      }
    }
  }

  &__blocks {
    .Block:first-of-type {
      padding-top: 0;
    }

    .BlockText__grid {
      display: block;
    }

    .BlockText__content {
      @include text-styles-lead;
    }
  }
}
