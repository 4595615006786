@import 'style/mixins';

.BlockCustom {
  position: relative;
  width: 100%;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }

  &__iframe {
    position: relative;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;

    &__ratio {
      height: 0;
      overflow: hidden;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
