@import 'style/mixins';

body.h-intro {
  overflow: hidden;
}

.Intro {
  cursor: pointer;

  &__header {
    @include typo-lead-sans;
    position: fixed;
    top: calc(var(--padding-vertical) - 12px);
    right: var(--padding-horizontal);
    left: var(--padding-horizontal);
    z-index: $z-index-intro-text;
    font-weight: 700;
    text-align: center;
    color: $color-white;
    mix-blend-mode: difference;
    user-select: none;
  }

  &__title {
    @include typo-logo;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: $z-index-intro-text;
    transform: translate3d(-50%, -50%, 0);
    color: $color-white;
    mix-blend-mode: difference;
    user-select: none;
  }

  &__enter {
    @include typo-lead-sans;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: $z-index-intro-text;
    height: var(--navigation-button-height);
    color: $color-grey-dark;
    background: $color-black;
    transform: translateX(-50%);
    appearance: none;
    outline: none;
    border: 0;
    user-select: none;
    cursor: pointer;
  }
}
