@import 'style/mixins';

.BlockRelatedEvent {
  position: relative;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }

  &__contents {
    padding: 0 var(--padding-horizontal);
    user-select: none;
    cursor: pointer;

    &__link {
      display: grid;
      grid-template-columns: max-content max-content 1fr;
      grid-template-areas: 'date title meta';
      gap: 0 var(--grid-gutter);

      @include viewport-zero-to-s {
        grid-template-columns: max-content 1fr;
        grid-template-areas:
          'date meta'
          'title title';
        border-top: $border-normal;
      }
    }

    &__date,
    &__title,
    &__meta {
      border-top: $border-normal;

      @include viewport-zero-to-s {
        border: 0;
      }
    }

    &__date {
      @include typo-meta-sans;
      grid-area: date;
      display: flex;
      align-items: center;
    }

    &__title {
      @include typo-lead-sans;
      grid-area: title;
      font-weight: 700;
      color: var(--color-secondary);

      @include viewport-zero-to-s {
        line-height: var(--fs-lead);
      }
    }

    &__meta {
      grid-area: meta;
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;

      &__type {
        @include typo-meta-sans;
      }

      &__icon {
        @include typo-lead-sans;
        position: relative;
        top: -3px;
        justify-self: right;
        color: var(--color-secondary);
      }
    }
  }
}
