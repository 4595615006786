@import 'style/mixins';

.BlockMeta {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__grid {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: var(--grid-gutter);

    &__item {
      @include text-styles-meta;
      padding-top: 5px;
      border-top: $border-normal;
    }
  }

  &--1 &__grid,
  &--2 &__grid,
  &--3 &__grid {
    @include viewport-zero-to-s {
      grid-template-columns: 1fr;
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }
  }

  &--4 &__grid {
    @include viewport-l {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-m {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-s {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-zero {
      grid-template-columns: 1fr;
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }
  }

  &--5 &__grid {
    @include viewport-l {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-m {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-s {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }

    @include viewport-zero {
      grid-template-columns: 1fr;
      grid-auto-columns: initial;
      grid-auto-flow: initial;
    }
  }
}
