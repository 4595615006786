@import 'style/mixins';

.CookieBanner {
  @include typo-meta-sans;
  position: fixed;
  bottom: calc(var(--navigation-button-height) + 10px);
  left: 50%;
  z-index: $z-index-cookie-banner;
  display: flex;
  align-items: center;
  height: var(--navigation-button-height);
  background: var(--color-bg-light);
  transform: translateX(-50%);

  &__link {
    margin-right: 10px;
    color: var(--color-secondary);
    font-weight: 700;

    @include viewport-zero-to-s {
      margin: 0;
    }
  }

  &__accept {
    height: 100%;
    padding: 5px 10px 5px 5px;
    font-weight: 700;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
  }
}
