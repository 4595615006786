@import 'style/mixins';

.ProjectIndex {
  position: relative;
  padding: var(--padding-vertical) var(--padding-horizontal)
    var(--padding-vertical-bottom);

  .h-has-cookie-banner & {
    padding-bottom: var(--padding-vertical-bottom-cookie);
  }

  &__title {
    @include typo-title;
    position: fixed;
    top: var(--padding-vertical);
    right: var(--padding-horizontal);
    left: var(--padding-horizontal);
    z-index: $z-index-project-index-title;
    color: var(--color-secondary);
  }

  &__underlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    mix-blend-mode: multiply;

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 17px var(--grid-gutter);

    @include viewport-m {
      grid-template-columns: repeat(3, 1fr);
    }

    @include viewport-s {
      grid-template-columns: repeat(3, 1fr);
    }

    @include viewport-xs {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport-zero {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    position: relative;
    display: block;
    z-index: 10;

    &--inactive {
      z-index: 5;
    }

    &__title h2 {
      @include typo-meta-sans;
      font-weight: 700;
      color: var(--color-secondary);
    }

    &__link {
      display: block;
    }

    &__meta {
      @include text-styles-meta;
    }

    &__figure {
      position: relative;
      width: 100%;
      margin: 0;

      &__ratio {
        height: 0;
        padding-top: 100%;
        overflow: hidden;
      }

      &__img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__abstract {
      @include text-styles-meta;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      display: grid;
      align-content: center;
      width: 100%;
      height: 100%;
      background: var(--color-bg);
      overflow: hidden;

      &__meta {
        padding-bottom: var(--lh-meta);
      }
    }
  }
}
