@import 'style/mixins';

.BlockAudio {
  position: relative;
  padding: 0 var(--padding-horizontal);
  background: $color-white;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__description {
    @include typo-meta-sans;
    font-weight: 700;
  }

  &__duration {
    @include typo-meta-sans;
    display: flex;

    &__text {
      font-weight: 700;
    }
  }

  &__tracks {
    padding-top: 10px;
  }
}
