@import 'style/mixins';

.Project {
  position: relative;
  padding: var(--padding-vertical) 0 var(--padding-vertical-bottom);

  .h-has-cookie-banner & {
    padding-bottom: var(--padding-vertical-bottom-cookie);
  }

  &__title {
    @include typo-title;
    padding: 0 var(--padding-horizontal);
  }

  &__abstract {
    @include text-styles-lead;
    padding: var(--lh-lead) var(--padding-horizontal) 0;
  }
}
