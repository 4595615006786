@import '../constants';

@mixin font-family($family) {
  @if $family == serif {
    font-family: 'JJannon', Times, serif;
    font-variant-numeric: oldstyle-nums;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == sans-serif {
    font-family: 'Peterman', Arial, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == monospace {
    font-family: 'Courier New', monospace;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else {
    @warn 'Unknown font-family specified, please check for spelling mistakes';
  }
}

/* typo definitions */
@mixin typo-logo {
  @include font-family('sans-serif');
  font-size: var(--fs-logo);
  font-style: normal;
  font-weight: 700;
  line-height: var(--lh-logo);
  text-align: center;
}

@mixin typo-title {
  @include font-family('sans-serif');
  font-size: var(--fs-title);
  font-style: normal;
  font-weight: 700;
  line-height: var(--lh-title);
  text-align: center;
}

@mixin typo-sub-title {
  @include font-family('sans-serif');
  font-size: var(--fs-sub-title);
  font-style: normal;
  font-weight: 700;
  line-height: var(--lh-sub-title);
  text-align: center;
}

@mixin typo-lead {
  @include font-family('serif');
  font-size: var(--fs-lead);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-lead);
}

@mixin typo-lead-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-lead-sans);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-lead);
}

@mixin typo-text {
  @include font-family('serif');
  font-size: var(--fs-text);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-text);
}

@mixin typo-text-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-text-sans);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-text);
}

@mixin typo-meta {
  @include font-family('serif');
  font-size: var(--fs-meta);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-meta);
}

@mixin typo-meta-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-meta-sans);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lh-meta);
}

@mixin typo-link-styles {
  color: var(--color-secondary);

  &:after {
    content: ' ↗';
  }

  &.h-link-internal:after {
    content: ' ↙';
  }

  &.h-link-tag {
    font-weight: 700;

    // &.h-link-tag-active {
    //   position: relative;
    //   z-index: $z-index-tag-text;
    //   color: $color-white;
    // }

    &:after {
      content: '';
    }
  }

  &.h-link-project {
    display: inline;
    font-weight: 700;
    border-top: $border-normal;

    .h-link-project-title {
      text-indent: 0;
      display: inline;
      padding-right: 10px;
      word-break: break-word;
    }

    .h-link-project-thumb {
      position: relative;
      top: var(--project-link-thumb-top);
      display: inline;
      width: auto;
      height: $project-thumb-height;
      vertical-align: bottom;
    }

    &:after {
      content: '';
    }
  }
}

/* text styles definitions */
@mixin text-styles-lead {
  @include typo-lead;

  i,
  em {
    font-style: italic;
  }

  strong {
    @include typo-lead-sans;
    font-weight: 700;
  }

  ul {
    margin: -1em 0 0;
    padding: 0 0 0 calc(1.7 * var(--lh-text));
    list-style: '•';

    li {
      padding-left: calc(1.3 * var(--lh-text));
    }
  }

  a {
    @include typo-lead-sans;
    @include typo-link-styles;
  }
}

@mixin text-styles-text {
  @include typo-text;

  i,
  em {
    font-style: italic;
  }

  strong {
    @include typo-text-sans;
    font-weight: 700;
  }

  ul {
    margin: -1em 0 0;
    padding: 0 0 0 calc(1.7 * var(--lh-text));
    list-style: '•';

    li {
      padding-left: calc(1.3 * var(--lh-text));
    }
  }

  a {
    @include typo-text-sans;
    @include typo-link-styles;
  }
}

@mixin text-styles-meta {
  @include typo-meta;

  i,
  em {
    font-style: italic;
  }

  strong {
    @include typo-meta-sans;
    font-weight: 700;
  }

  ul {
    margin: -1em 0 0;
    padding: 0 0 0 calc(1.7 * var(--lh-text));
    list-style: '•';

    li {
      padding-left: calc(1.3 * var(--lh-text));
    }
  }

  a {
    @include typo-meta-sans;
    @include typo-link-styles;
  }
}

/* text selection */
@mixin text-selection {
  ::-moz-selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }

  ::selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }
}

/* general typo settings */
h1,
h2 {
  margin: 0;
}

p {
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.h-text-lead {
  @include text-styles-lead;
}

.h-text-meta {
  @include text-styles-meta;
}
