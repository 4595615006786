@import 'style/mixins';

.BlockComments {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__form {
    padding-bottom: calc(0.5 * var(--lh-text));

    &__title {
      @include typo-sub-title;
      position: relative;
      padding: 0 var(--padding-horizontal)
        $content-block-sub-title-padding-bottom;
    }

    &__container {
      display: grid;
      grid-template-columns: 25fr 75fr;
      gap: 5px var(--grid-gutter);

      @include viewport-zero-to-s {
        grid-template-columns: 1fr;
      }

      &.h-disabled {
        cursor: progress;
        opacity: 0.2;

        input,
        textarea {
          cursor: progress;
        }
      }
    }

    &__name {
      @include typo-meta-sans;
      width: 100%;
      height: var(--lh-lead);
      color: $color-grey-dark;
      font-weight: 700;
      appearance: none;
      border: 0;
      border-radius: 0;
      outline: none;
      background: var(--color-bg-light);
    }

    &__handle {
      position: absolute !important;
      width: 0 !important;
      height: 0 !important;
      overflow: hidden !important;
    }

    &__comment {
      @include typo-lead;
      width: 100%;
      padding: 0;
      color: $color-grey-dark;
      appearance: none;
      border: 0;
      border-radius: 0;
      outline: none;
      background: var(--color-bg-light);
    }

    &__submit {
      @include typo-lead-sans;
      position: relative;
      top: -7px;
      padding: 0;
      font-weight: 700;
      color: var(--color-secondary);
      background: transparent;
      appearance: none;
      outline: none;
      border: 0;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
      }
    }

    &__error {
      @include typo-meta-sans;
      padding-top: 5px;
    }

    &__right &__error {
      padding: 0;
      margin-top: -2px;
    }

    &__processing {
      @include typo-meta-sans;
    }

    &__success {
      &__preview {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-areas: 'meta content';
        gap: 0 var(--grid-gutter);
        color: $color-grey-dark;

        @include viewport-zero-to-s {
          grid-template-columns: 1fr;
          grid-template-areas: 'meta' 'content';
          border-top: $border-normal;
        }

        &__meta,
        &__content {
          border-top: $border-normal;

          @include viewport-zero-to-s {
            border: 0;
          }
        }

        &__meta {
          @include typo-meta-sans;
          grid-area: meta;
          padding-top: 5px;

          @include viewport-zero-to-s {
            padding-top: 2px;
          }

          &__name {
            font-weight: 700;
          }
        }

        &__content {
          @include typo-lead;
          grid-area: content;
        }
      }

      &__hint {
        @include typo-meta-sans;
      }
    }
  }

  &__comments {
    &__title {
      @include typo-sub-title;
      position: relative;
      padding: 0 var(--padding-horizontal)
        $content-block-sub-title-padding-bottom;
    }

    &__item {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-areas: 'meta content';
      gap: 0 var(--grid-gutter);

      @include viewport-zero-to-s {
        grid-template-columns: 1fr;
        grid-template-areas: 'meta' 'content';
        border-top: $border-normal;
      }

      &__meta,
      &__content {
        border-top: $border-normal;

        @include viewport-zero-to-s {
          border: 0;
        }
      }

      &__meta {
        @include typo-meta-sans;
        grid-area: meta;
        padding-top: 5px;
        max-width: 35vw;

        @include viewport-zero-to-s {
          max-width: none;
          padding-top: 2px;
        }

        &__name {
          font-weight: 700;
        }
      }

      &__content {
        @include typo-lead;
        grid-area: content;
      }
    }
  }
}
