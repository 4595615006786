@import 'style/mixins';

.BlockAssetGrid {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &--2 &__items {
    grid-template-columns: repeat(2, 1fr);
  }

  &--3 &__items {
    grid-template-columns: repeat(3, 1fr);

    @include viewport-xs {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport-zero {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--4 &__items {
    grid-template-columns: repeat(4, 1fr);

    @include viewport-s {
      grid-template-columns: repeat(3, 1fr);
    }

    @include viewport-xs {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport-zero {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__items {
    display: grid;
    gap: 0 var(--grid-gutter);
  }

  &__video {
    position: relative;
    width: 100%;
    margin: 0;
  }

  &__figure {
    position: relative;
    width: 100%;
    max-height: 90vh;
    margin: 0;
  }

  &__ratio {
    height: 0;
    padding-top: 100%;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__caption {
    @include text-styles-meta;
  }
}
