@import 'style/mixins';

.BlockRelatedResearch {
  position: relative;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }

  &__citation {
    @include text-styles-meta;
    padding: 0 var(--padding-horizontal) var(--lh-text);

    &__link {
      a:after {
        content: '';
      }
    }

    &__content {
      padding-top: 5px;
      border-top: $border-normal;
    }
  }

  &__slideshow {
    position: relative;
    padding: 0 var(--padding-horizontal);
    border-bottom: $border-normal;

    &__item {
      &.h-abstract {
        width: calc(8 / 12 * 100vw);
        height: auto;
      }

      &.h-text {
        width: calc(2 * 8 / 12 * 100vw);
      }
    }

    .h-slideshow-button-bg {
      position: absolute;
      top: 0;
      z-index: 10;
      width: var(--slideshow-button-width);
      height: 100%;
      background: var(--color-secondary);
      mix-blend-mode: multiply;
      user-select: none;
      cursor: pointer;
    }

    .h-slideshow-button-icon {
      @include typo-lead-sans;
      position: absolute;
      top: 0;
      z-index: 15;
      width: var(--slideshow-button-width);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      user-select: none;
      cursor: pointer;
    }

    &__button__left,
    &__button__right {
      &.swiper-button-disabled {
        display: none;
      }
    }

    &__button__left {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        left: 0;
      }
    }

    &__button__right {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        right: 0;
      }
    }
  }

  &__abstract {
    @include typo-text-sans;
    position: relative;
    top: 50%;
    font-weight: 700;
    transform: translateY(-60%);
  }

  &__text {
    @include text-styles-text;
    column-count: 2;
    column-gap: $grid-gutter;
    padding-bottom: calc(1.5 * var(--lh-text));

    p + p {
      text-indent: $indent-text;
    }
  }
}
