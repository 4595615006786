@import 'style/mixins';

.Navigation {
  @include hide-scrollbars;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-navigation;
  padding: 0 var(--padding-horizontal);
  width: 100%;
  overflow: scroll;

  &__grid {
    display: grid;
    grid-template-columns: auto max-content;
  }

  &__center {
    display: flex;
    justify-content: center;
  }

  &__link {
    @include typo-text-sans;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: var(--navigation-button-height);
    padding: 0 var(--navigation-button-padding-right) 0 0;
    margin: 0 5px;
    color: $color-white;
    background: $color-black;

    &:first-of-type {
      margin-left: 10px;
    }

    &:last-of-type {
      margin-right: 10px;
    }

    @include viewport-zero-to-s {
      &:first-of-type {
        margin-left: 0;
      }
    }

    &--active {
      font-weight: 700;
    }
  }

  &__project {
    @include typo-text-sans;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: var(--navigation-button-height);
    margin: 0 5px;
    color: $color-black;
    font-weight: 700;

    @include viewport-l {
      display: none;
    }

    @include viewport-m {
      display: none;
    }

    @include viewport-s {
      display: none;
    }

    @include viewport-xs {
      display: none;
    }

    @include viewport-zero {
      display: none;
    }
  }

  &__btn {
    @include typo-text-sans;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: var(--navigation-button-height);
    padding: 0 var(--navigation-button-padding-right) 0 0;
    margin: 0;
    appearance: none;
    color: $color-white;
    background: $color-black;
    border: 0;
    outline: 0;
    cursor: pointer;

    &--lang {
      @include viewport-zero-to-s {
        margin-right: var(--padding-horizontal);
      }
    }
  }
}
