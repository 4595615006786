@import 'style/mixins';

.BlockReferences {
  position: relative;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }

  &__contents {
    &__item {
      @include text-styles-meta;
      padding: 0 var(--padding-horizontal) calc(0.5 * var(--lh-meta));

      &__source {
        padding-top: 5px;
        border-top: $border-normal;
      }

      &__link {
        a:after {
          content: '';
        }
      }
    }
  }
}
