@import 'style/mixins';

.BlockPress {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__item {
    display: grid;
    grid-template-areas: 'meta figure content';
    grid-template-columns: max-content max-content 1fr;
    gap: 0 var(--grid-gutter);
    margin-bottom: 20px;

    @include viewport-m {
      grid-template-areas:
        'meta meta figure'
        'content content content';
    }

    @include viewport-zero-to-s {
      grid-template-areas:
        'meta meta figure'
        'content content content';
    }

    &__meta {
      @include text-styles-meta;
      position: relative;
      grid-area: meta;
      padding-top: 5px;
      border-top: $border-normal;

      a {
        font-weight: 400;
      }
    }

    &__figure {
      position: relative;
      grid-area: figure;
      margin: 0;

      &__img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__content {
      position: relative;
      grid-area: content;
      padding-top: 5px;
      border-top: $border-normal;

      @include viewport-m {
        border-top: 0;
      }

      @include viewport-zero-to-s {
        border-top: 0;
      }
    }

    &__lead {
      @include text-styles-lead;
      margin-top: -4px;
    }

    &__text {
      @include text-styles-meta;
    }
  }
}
