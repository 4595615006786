@import 'style/mixins';

.Tag {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-tag;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  &__header {
    @include typo-meta-sans;
    position: fixed;
    top: 5px;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: $color-grey;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    &__button {
      @include typo-meta-sans;
      position: relative;
      left: 50%;
      height: var(--navigation-button-height);
      padding: 0 var(--navigation-button-padding-right) 0 0;
      color: $color-black;
      background: $color-white;
      font-weight: 700;
      appearance: none;
      outline: 0;
      border: 0;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }

  &__content {
    position: absolute;
    top: 50vh;
    right: var(--padding-horizontal);
    bottom: 0;
    left: var(--padding-horizontal);
    padding-bottom: calc(var(--navigation-button-height) - 10px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-secondary);
    }
  }

  &__section {
    position: relative;
    color: $color-white;
    margin: 0 0 10px;

    &--padding-zero {
      margin-bottom: 0;
    }

    &__title {
      @include typo-sub-title;
      padding: 10px 0;
      background: $color-black;

      &__content {
        color: $color-secondary;
      }
    }

    &__content {
      @include text-styles-meta;

      @include viewport-zero-to-s {
        @include text-styles-text;
      }

      &.h-type-topic {
        padding: 10px 0;
        background: $color-black;
      }

      &.h-type-projects,
      &.h-type-events,
      &.h-type-more {
        display: flex;
        flex-flow: row wrap;
      }
    }
  }

  &__project {
    padding: 40px 60px 40px 0;
    margin: 0 $grid-gutter $grid-gutter 0;
    background: $color-black;

    a {
      @include typo-lead-sans;
      font-weight: 700;

      &:after {
        content: '';
      }
    }
  }

  &__event {
    position: relative;
    width: 100%;

    &__link {
      display: grid;
      grid-template-columns: max-content max-content 1fr;
      grid-template-areas: 'date title meta';
      gap: 0 var(--grid-gutter);
      width: 100%;
      color: var(--color-bg) !important;

      &:after {
        content: '' !important;
      }

      @include viewport-zero-to-s {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'date meta'
          'title title';
      }
    }

    &__date {
      @include typo-meta-sans;
      grid-area: date;
      display: flex;
      align-items: center;
      background: $color-black;

      strong {
        @include typo-meta-sans;
        font-weight: 700;
      }
    }

    &__title {
      @include typo-lead-sans;
      grid-area: title;
      padding: 5px 10px 5px 0;
      font-weight: 700;
      color: var(--color-secondary);
      background: $color-black;
    }

    &__meta {
      @include typo-meta-sans;
      grid-area: meta;
      display: flex;
      align-items: center;
      background: $color-black;

      strong {
        @include typo-meta-sans;
        font-weight: 700;
      }
    }
  }

  &__excerpt {
    padding: 10px 0;
    background: $color-black;

    + .Tag__excerpt {
      margin-top: 10px;
    }

    &__related {
      @include typo-meta-sans;

      a {
        font-weight: 700;

        &:after {
          content: '';
        }
      }
    }

    &__text {
      @include text-styles-text;
    }
  }

  &__more {
    padding: 5px 10px 5px 0;
    margin-right: $grid-gutter;
    background: $color-black;

    a {
      @include typo-lead-sans;
      font-weight: 700;

      &:after {
        content: '';
      }
    }
  }
}
