@import 'style/mixins';

.BlockSlideshow {
  position: relative;
  width: 100%;

  &__title {
    @include typo-sub-title;
    position: relative;
    padding: 0 var(--padding-horizontal) $content-block-sub-title-padding-bottom;
  }

  &__slideshow {
    position: relative;
    width: 100%;
    height: 50vh;
    user-select: none;

    .swiper-slide:first-of-type {
      margin-left: calc(
        var(--padding-horizontal) - var(--slideshow-button-width) - 10px
      );

      .BlockSlideshow__slideshow__item__figure__img {
        max-width: calc(100vw - var(--slideshow-button-width) - 20px);
      }
    }

    .swiper-slide:last-of-type {
      margin-right: var(--padding-horizontal);

      .BlockSlideshow__slideshow__item__figure__img {
        max-width: calc(100vw - var(--slideshow-button-width) - 20px);
      }
    }

    &__item {
      height: 100%;

      &__figure {
        height: 100%;
        margin: 0;

        &__img {
          width: auto;
          max-width: calc(100vw - 2 * var(--slideshow-button-width) - 20px);
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      flex: 0 0 auto;
      height: 100%;
      width: auto;
    }

    .h-slideshow-button-bg {
      position: absolute;
      top: 0;
      z-index: 10;
      width: var(--slideshow-button-width);
      height: 100%;
      background: var(--color-secondary);
      mix-blend-mode: multiply;
      user-select: none;
      cursor: pointer;
    }

    .h-slideshow-button-icon {
      @include typo-lead-sans;
      position: absolute;
      top: 0;
      z-index: 15;
      width: var(--slideshow-button-width);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      user-select: none;
      cursor: pointer;
    }

    &__button__left,
    &__button__right {
      &.swiper-button-disabled {
        display: none;
      }
    }

    &__button__left {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        left: 0;
      }
    }

    &__button__right {
      .h-slideshow-button-bg,
      .h-slideshow-button-icon {
        right: 0;
      }
    }
  }

  &__caption {
    @include text-styles-meta;
    padding: 0 var(--padding-horizontal);
  }
}
