@import 'style/mixins';

.Privacy {
  position: relative;
  padding: var(--padding-vertical) var(--padding-horizontal)
    var(--padding-vertical-bottom);

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: calc(0.5 * var(--lh-meta));
    border-bottom: $border-normal;
    margin-bottom: 5px;
  }

  &__contents {
    column-count: 3;
    column-gap: $grid-gutter;

    @include viewport-s {
      column-count: 2;
    }

    @include viewport-xs {
      column-count: 1;
    }

    @include viewport-zero {
      column-count: 1;
    }

    &__item {
      break-inside: avoid-column;
      page-break-inside: avoid;

      &__title {
        @include typo-sub-title;
        text-align: center;
      }

      &__content {
        @include text-styles-meta;
        margin-bottom: var(--lh-meta);
      }
    }
  }
}
