@import 'style/mixins';

.BlockRelatedProject {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__grid {
    display: grid;
    grid-template-columns: max-content minmax(auto, max-content);

    @include viewport-xs {
      display: inline-block;
    }

    @include viewport-zero {
      display: inline-block;
    }
  }

  &__hint {
    @include typo-meta-sans;
    align-self: center;
    padding-right: 7px;
  }

  &__link {
    display: grid;
    grid-template-columns: auto max-content;
    border-top: $border-normal;

    &__title {
      @include typo-lead-sans;
      padding-right: 10px;
      font-weight: 700;
      color: var(--color-secondary);
    }

    &__thumb {
      position: relative;
      top: -1px;
      width: auto;
      height: $project-thumb-height;
    }
  }
}
