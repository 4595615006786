@import 'style/mixins';

.BlockLinkList {
  position: relative;
  padding: 0 var(--padding-horizontal);

  &__title {
    @include typo-sub-title;
    position: relative;
    padding-bottom: $content-block-sub-title-padding-bottom;
  }

  &__content {
    @include text-styles-meta;
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    padding: 0 20px 10px 0;

    a:after {
      content: '';
    }
  }
}
