@import 'style/mixins';

.BlockEvents {
  position: relative;
  padding: 0;

  .h-has-cookie-banner & {
    padding-bottom: var(--padding-vertical-bottom-cookie);
  }

  &__item {
    margin-bottom: 12px;

    &__teaser {
      padding: 0 var(--padding-horizontal);
      user-select: none;
      cursor: pointer;

      &__grid {
        display: grid;
        grid-template-columns: max-content auto 1fr;
        grid-template-areas: 'date title meta';
        gap: 0 var(--grid-gutter);

        @include viewport-zero-to-s {
          grid-template-columns: max-content 1fr;
          grid-template-areas:
            'date meta'
            'title title';
          border-top: $border-normal;
        }
      }

      &__date,
      &__title,
      &__meta {
        border-top: $border-normal;

        @include viewport-zero-to-s {
          border: 0;
        }
      }

      &__date {
        @include typo-meta-sans;
        grid-area: date;
        display: flex;
        align-items: center;
      }

      &__title {
        @include typo-lead-sans;
        grid-area: title;
        font-weight: 700;
        color: var(--color-secondary);

        @include viewport-zero-to-s {
          line-height: var(--fs-lead);
        }
      }

      &__meta {
        grid-area: meta;
        display: grid;
        grid-template-columns: 1fr 30px;
        align-items: center;

        &__type {
          @include typo-meta-sans;
        }

        &__icon {
          @include typo-lead-sans;
          position: relative;
          top: -3px;
          justify-self: right;
          color: var(--color-secondary);
        }
      }
    }

    &__detail {
      overflow: hidden;

      // 1. normal view
      &__normal {
        width: 100%;
        padding: 10px var(--padding-horizontal) 0;
      }

      &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 var(--grid-gutter);

        @include viewport-zero-to-s {
          grid-template-columns: 1fr;
        }

        .BlockRelatedProject {
          padding: 0;
        }
      }

      &__figure {
        position: relative;
        width: 100%;
        margin: 0;

        &__ratio {
          height: 0;
          overflow: hidden;
        }

        &__img {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__right {
        display: flex;
        flex-flow: column;
      }

      &__lead {
        @include text-styles-meta;
        font-weight: 700;
        padding-bottom: var(--lh-meta);
      }

      &__text {
        @include text-styles-meta;
      }

      &__bottom {
        padding-top: $content-block-padding-small-top;
        margin: auto 0 0;

        @include viewport-zero-to-s {
          padding-top: $content-block-padding-small-top;
        }

        div + div {
          padding-top: 10px;
        }

        &__link {
          display: grid;
          grid-template-columns: max-content max-content;

          &__hint {
            @include typo-meta-sans;
            align-self: center;
            padding-right: 7px;
          }

          &__anchor {
            @include typo-lead-sans;
            color: var(--color-secondary);

            &:after {
              content: ' ↗';
            }
          }
        }
      }

      // 2. archive view
      &__archive {
        padding-top: 10px;

        .BlockRelatedProject__grid {
          width: 50%;
          margin-left: 50%;
        }
      }
    }
  }
}
