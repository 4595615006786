@font-face {
  font-family: 'JJannon';
  src: url('fonts/JJannon-Regular.woff2') format('woff2'),
    url('fonts/JJannon-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'JJannon';
  src: url('fonts/JJannon-Italic.woff2') format('woff2'),
    url('fonts/JJannon-Italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Peterman';
  src: url('fonts/Peterman-13-Regular.woff2') format('woff2'),
    url('fonts/Peterman-13-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Peterman';
  src: url('fonts/Peterman-13-Bold.woff2') format('woff2'),
    url('fonts/Peterman-13-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
